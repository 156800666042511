














import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({ components: { ModalWrapper } })
export default class LockedPage extends Vue {

}
